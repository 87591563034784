<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item link>
          <v-list-item-action>
            <v-icon>mdi-storefront-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Menu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-action>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Order</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{this.$store.state.shop['name_'+locale]}} 枱號:{{tableNumber}}</v-toolbar-title>
    </v-app-bar>

    <v-main class="pa-0">

      <v-container class="fill-height" fluid>
        <v-row style="margin: 0;" align="center" justify="center">
          <router-link to="/takeAwayMenu" class="start_order" v-if="this.$store.state.shop.id > 0 ">
        	  <div class="text-center">
			        <div class="home_company_logo">
                <img :src="iconImage" />
			        </div>
	            <div class="home_title">歡迎光臨{{this.$store.state.shop_id}}</div>
	            <div class="home_subtitle">{{this.$store.state.shop['name_'+locale]}}</div>
              <template v-if="locale=='en'">

                <div class="home_address">
                  {{this.$store.state.shop.address_line_1_en?this.$store.state.shop.address_line_1_en:''}}<br>
                  {{this.$store.state.shop.address_line_2_en?this.$store.state.shop.address_line_2_en:''}}<br>
                  {{this.$store.state.shop.address_line_3_en?this.$store.state.shop.address_line_3_en:''}}
                </div>
              </template>
              <template v-if="locale=='zh'">

                <div class="home_address">
                  {{this.$store.state.shop.address_line_1_zh?this.$store.state.shop.address_line_1_zh:''}}<br>
                  {{this.$store.state.shop.address_line_2_zh?this.$store.state.shop.address_line_2_zh:''}}<br>
                  {{this.$store.state.shop.address_line_3_zh?this.$store.state.shop.address_line_3_zh:''}}
                </div>
              </template>
              <template v-if="this.$store.state.orderingEnd" >
                點餐時間到{{this.endTime}}
              </template>

              <div class="click_to_start">按此開始點餐</div>


              
              <div class="footer_credit">&copy; 2023 Joydea! All Rights Reserved</div>
	          </div>
          </router-link>
          <div v-if="this.$store.state.shop.id ===''">
            <div class="text-center">
              <div class="home_company_logo">
                <img :src="iconImage" />
              </div>
              <div class="home_title">歡迎光臨</div>
              <div class="home_subtitle">{{this.$store.state.shop['name_'+locale]}}</div>
              <template v-if="locale=='en'">
            
                <div class="home_address">
                  {{this.$store.state.shop.address_line_1_en?this.$store.state.shop.address_line_1_en:''}}<br>
                  {{this.$store.state.shop.address_line_2_en?this.$store.state.shop.address_line_2_en:''}}<br>
                  {{this.$store.state.shop.address_line_3_en?this.$store.state.shop.address_line_3_en:''}}
                </div>
              </template>
              <template v-if="locale=='zh'">
            
                <div class="home_address">
                  {{this.$store.state.shop.address_line_1_zh?this.$store.state.shop.address_line_1_zh:''}}<br>
                  {{this.$store.state.shop.address_line_2_zh?this.$store.state.shop.address_line_2_zh:''}}<br>
                  {{this.$store.state.shop.address_line_3_zh?this.$store.state.shop.address_line_3_zh:''}}
                </div>
              </template>



            
              <h1 class="start_order" v-if="this.$store.state.shop.id ===''">
                <div id="rescan">請掃描二維碼或重新整理頁面</div>
              </h1>
              
              <div class="footer_credit">&copy; 2023 Joydea! All Rights Reserved</div>
            </div>
          </div>
        </v-row>
      
      </v-container>
      <div class="row justify-center"><div class="v-dialog__container"><!----></div></div>
    </v-main>
  </v-app>
  
</template>


<style scoped>
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    background-image: '/img/facade.jpg';
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
  }
</style>

<script>
export default {
  props: {
    source: String
  },

  created(){

    if(this.$route.params.statichash){
      console.log('static hash detected')
      this.$store.commit("setStaticHash",this.$route.params.statichash);
      this.$store.dispatch("getTableByStaticHash",this.$route.params.statichash);

      //this.$store.commit("setHash",this.$route.params.hash);
      
    }else{
      console.log('Dynamic hash detected')
      this.$store.commit("setHash",this.$route.params.hash);
      this.$store.dispatch("getTableByHash",this.$route.params.hash);
    }
    

    console.log('loaded',this.$store.state)

  },
  mounted(){
    document.querySelector('html').classList.add("overflow_hidden");
    // if(document.getElementById('rescan')){
    //     location.reload();
    //   }
    //alert('theme')
    // if(this.$store.state.shop.color_theme){
    //   alert('theme')
    //   document.querySelector('html').classList.add(this.$store.state.shop.color_theme);
    // }
    
    setTimeout(() => {
      if(document.getElementById('rescan')){
        //location.reload();
        this.$store.dispatch("getTableByHash",this.$route.params.hash);
      }else{
        //alert('fine')
      }
    }, "500")


  },
  data: () => ({
    drawer: null,
    dialog: false,
    
  }),

  computed: {
    server: function () {
      return this.$store.state.server;
    },
    imgServer: function () {
      return this.$store.state.imgServer;
    },
    locale: function () {
      return this.$store.state.locale;
    },
    shop: function () {
      return this.$store.state.shop;
    },
    company: function () {
      return this.$store.state.company;
    },
    tableNumber: function () {
      return this.$store.state.tableNumber;
    },
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
    iconImage: function(){

      if(typeof this.$store.state.shop.image !== "undefined" && this.$store.state.shop.image !=''){
        return this.imgServer + "imageHash/"+this.$store.state.shop.image;
      }else{
        return '/img/logo_v.png';
      }
        
    },
    endTime :function(){
      var d = new Date(this.$store.state.orderingEnd);
      return d.getHours().toString().padStart(2, '0')+":"+d.getMinutes().toString().padStart(2, '0');
    }
  },
};
</script>